export const errorCodeMessages = {
  // Division by zero
  DIV_BY_ZERO: 'This happens when a mathematical operation attempts to divide by zero',
  // Unknown function name
  NAME: 'This happens when a function you entered does not exist or is unsupported',
  VALUE: 'This error occurs whenever the data type a function is given doesn’t match what it is expecting',
  NUM: 'The number is too large or too small to be displayed',
  NA: 'Occurs when a function cannot find the value it is being asked to look for',
  // Cyclic dependency
  CYCLE: "Means there's a circular reference. This happens when a formula refers back to its own cell value",
  // Wrong reference.
  REF: 'This happen when a cell reference is deleted or moved',
  // Array spill error.
  SPILL: 'Happens when the formula is applied to the data because there is text or some data inside the spill range',
  // Calculation error.
  CALC: "Happens when a formula doesn't know how to calculate",
  // Generic error
  ERROR: 'An unspecified or unknown error occured',
  // In the case of SUM(B1 C1)
  NULL: 'Occurs requested the result of overlapping ranges'
};