import { spreadsheet as coreSpreadsheet } from '@industriousapps/excelkits-core';
import hyperFormulaService, { workbookToHyperFormula, hyperFormulaToWorkbook } from '@/services/hyperFormula';
import { errorCodeMessages } from '@/config/spreadsheet';
import univerService from '@/services/univerjs';
import workbookConfig from '@/config/workbook';
import deepClone from '@/utils/deepClone';
import { createWrappedError } from '@/utils/errors';
const PREFIX = `common: utils: spreadsheets:`;
const {
  toCellRefs,
  getSpreadsheetIndex
} = coreSpreadsheet;
export default {
  // Create compiled workbook, with all logic interpreted,
  // throwing an error when any Excel errors have occurred
  strictCompile(srcWorkbook, sheetNames, workbookEngine) {
    if (workbookEngine === '1') {
      return univerService.workbookToUniverFacade(srcWorkbook, sheetNames).then(univerAPI => {
        const errors = univerService.filterErrors(univerAPI);

        if (errors.length) {
          const err = new Error(`${PREFIX} strictCompile: Univer contains errors`); // @ts-ignore

          err.items = errors;
          throw err;
        }

        try {
          return univerService.univerFacadeToWorkbook({
            univerAPI
          });
        } catch (err) {
          throw createWrappedError(`${PREFIX} strictCompile: Univer failed to convert to workbook`, err);
        }
      }).catch(err => {
        throw createWrappedError(`${PREFIX} strictCompile: Univer failed to create facade`, err);
      });
    }

    return hyperFormulaService.load().then(() => {
      const hfInstance = workbookToHyperFormula(srcWorkbook, sheetNames);
      const errors = hyperFormulaService.filterErrors(hfInstance, srcWorkbook);

      if (errors.some(errorSheet => errorSheet.length)) {
        const err = new Error(`${PREFIX} strictCompile: HyperFormula contains errors`); // @ts-ignore

        err.items = errors.map(errorSheet => errorSheet.map(hyperFormulaService.toSpreadsheetError));
        throw err;
      }

      try {
        return hyperFormulaToWorkbook(hfInstance);
      } catch (err) {
        throw createWrappedError(`${PREFIX} strictCompile: HyperFormula failed to convert to workbook`, err);
      }
    }).catch(err => {
      const wrappedError = createWrappedError(`${PREFIX} strictCompile: unexpected error`, err);

      if (err.items) {
        // @ts-ignore
        wrappedError.items = err.items;
      }

      throw wrappedError;
    });
  },

  // Creates a factory for updating
  // single cell selections within a workbook
  // NOTE: generates new instance of source workbook
  createCellUpdater(srcWorkbook) {
    const workbook = deepClone(srcWorkbook);
    return (selection, value) => {
      if (selection.search(':') > -1) {
        throw Error(`${PREFIX} createCellUpdater: only single cell selections are acceptable, given ${selection}`);
      }

      const sheetIndex = getSpreadsheetIndex(selection);
      const cellReferences = toCellRefs(selection);
      const [address] = coreSpreadsheet.cellReferencesToAddress(cellReferences);
      const sheet = workbook[sheetIndex] || [];
      const row = sheet[address.row];
      if (row) row[address.col] = value;
      return workbook;
    };
  }

}; // Check if a value is compatible
// as a workbook or should be consider
// a spreadsheet

export function isWorkbook(val) {
  return Array.isArray(val) && Array.isArray(val[0]) && Array.isArray(val[0][0]);
} // Attempts to create a source url based
// on workbook's origin information

export function getWorkbookSourceUrl(workbook) {
  const fileOriginEditor = getFileOriginEditor(workbook.originFileType);

  if (fileOriginEditor === 'google-sheets' && workbook !== null && workbook !== void 0 && workbook.originFileId) {
    return `https://drive.google.com/open?id=${workbook === null || workbook === void 0 ? void 0 : workbook.originFileId}`;
  }

  return '';
} // Get the origin editor of a file

export function getFileOriginEditor(mimeType) {
  if (mimeType !== null && mimeType !== void 0 && mimeType.includes('google-apps')) {
    return 'google-sheets';
  }

  if (mimeType !== null && mimeType !== void 0 && mimeType.includes('ms-excel') || mimeType !== null && mimeType !== void 0 && mimeType.includes('openxmlformats-officedocument')) {
    return 'excel';
  }

  if (mimeType !== null && mimeType !== void 0 && mimeType.includes('apple.numbers')) {
    return 'numbers';
  }

  return 'unknown';
}
export const getWorkbookWarningMsg = warnings => {
  if (warnings !== null && warnings !== void 0 && warnings.oversized) {
    return workbookConfig.oversizedWarning;
  }

  if (warnings !== null && warnings !== void 0 && warnings.syncUserDelete) {
    return workbookConfig.syncUserDeleteWarning;
  }

  return '';
}; // Return a configured error message
// by spreadsheet error code

export const getSpreadsheetErrorMsg = code => {
  return errorCodeMessages[code] || "There's an unknown error in this cell";
};