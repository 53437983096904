import flatten from 'lodash/flatten';
import { HyperFormula } from 'hyperformula';
import { spreadsheet as spreadsheetCore } from '@industriousapps/excelkits-core';
import { spreadsheet as coreSpreadsheet } from '@industriousapps/excelkits-core';
import hyperFormulaConfig from '@/config/hyperFormula';
const {
  getSheetName
} = spreadsheetCore;
const PREFIX = 'common: services: HyperFormula:';
const {
  coordinatesToAddress
} = coreSpreadsheet;
let hyperFormula;
export default {
  load() {
    if (hyperFormula) {
      return Promise.resolve(hyperFormula);
    }

    return import('hyperformula').catch(err => {
      throw Error(`${PREFIX} load: failed to lazy-load HyperFormula: ${err}`);
    }).then(hfPackage => {
      try {
        applyCustomPlugins(hfPackage);
      } catch (err) {
        throw Error(`${PREFIX} load: failed to add custom plugins: ${err}`);
      } // Add modified hyperformula to
      // local memory for subsequent loads


      hyperFormula = hfPackage.HyperFormula; // Resolve modified hyperformula

      return hyperFormula;
    });
  },

  filterErrors,
  toSpreadsheetError,
  workbookToHyperFormula,
  workbookToSpreadsheetErrors
}; // Add non-namespaced generic functions that
// are functionally equivalent to namespaced
// NOTE: creates side effects

export function applyCustomPlugins(_ref) {
  let {
    HyperFormula: Hf,
    FunctionPlugin: Fp,
    FunctionArgumentType: Fa
  } = _ref;

  // Source:
  // https://github.com/handsontable/hyperformula/blob/b8517a76fcbe0858bbcf9ff4441e31976b9fa91a/src/interpreter/plugin/SimpleArithmertic.ts#L13
  class ExkSimpleArithmerticPlugin extends Fp {
    add(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('ADD'), this.arithmeticHelper.addWithEpsilon);
    }

    concat(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('CONCAT'), this.arithmeticHelper.concat);
    }

    divide(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('DIVIDE'), this.arithmeticHelper.divide);
    }

    eq(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('EQ'), this.arithmeticHelper.eq);
    }

    gt(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('GT'), this.arithmeticHelper.gt);
    }

    gte(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('GTE'), this.arithmeticHelper.geq);
    }

    lt(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('LT'), this.arithmeticHelper.lt);
    }

    lte(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('LTE'), this.arithmeticHelper.leq);
    }

    minus(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('MINUS'), this.arithmeticHelper.subtract);
    }

    multiply(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('MULTIPLY'), this.arithmeticHelper.multiply);
    }

    ne(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('NE'), this.arithmeticHelper.neq);
    }

    pow(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('POW'), this.arithmeticHelper.pow);
    }

    uminus(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('UMINUS'), this.arithmeticHelper.unaryMinus);
    }

    upercent(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('UNARY_PERCENT'), this.arithmeticHelper.unaryPercent);
    }

    uplus(ast, state) {
      return this.runFunction(ast.args, state, this.metadata('UPLUS'), this.arithmeticHelper.unaryPlus);
    }

  }

  ExkSimpleArithmerticPlugin.implementedFunctions = {
    ADD: {
      method: 'add',
      parameters: [{
        argumentType: Fa.NUMBER,
        passSubtype: true
      }, {
        argumentType: Fa.NUMBER,
        passSubtype: true
      }]
    },
    CONCAT: {
      method: 'concat',
      parameters: [{
        argumentType: Fa.STRING,
        passSubtype: true
      }, {
        argumentType: Fa.STRING,
        passSubtype: true
      }]
    },
    DIVIDE: {
      method: 'divide',
      parameters: [{
        argumentType: Fa.NUMBER,
        passSubtype: true
      }, {
        argumentType: Fa.NUMBER,
        passSubtype: true
      }]
    },
    EQ: {
      method: 'eq',
      parameters: [{
        argumentType: Fa.NOERROR,
        passSubtype: true
      }, {
        argumentType: Fa.NOERROR,
        passSubtype: true
      }]
    },
    GT: {
      method: 'gt',
      parameters: [{
        argumentType: Fa.NOERROR,
        passSubtype: true
      }, {
        argumentType: Fa.NOERROR,
        passSubtype: true
      }]
    },
    GTE: {
      method: 'gte',
      parameters: [{
        argumentType: Fa.NOERROR,
        passSubtype: true
      }, {
        argumentType: Fa.NOERROR,
        passSubtype: true
      }]
    },
    LT: {
      method: 'lt',
      parameters: [{
        argumentType: Fa.NOERROR,
        passSubtype: true
      }, {
        argumentType: Fa.NOERROR,
        passSubtype: true
      }]
    },
    LTE: {
      method: 'lte',
      parameters: [{
        argumentType: Fa.NOERROR,
        passSubtype: true
      }, {
        argumentType: Fa.NOERROR,
        passSubtype: true
      }]
    },
    MINUS: {
      method: 'minus',
      parameters: [{
        argumentType: Fa.NUMBER,
        passSubtype: true
      }, {
        argumentType: Fa.NUMBER,
        passSubtype: true
      }]
    },
    MULTIPLY: {
      method: 'multiply',
      parameters: [{
        argumentType: Fa.NUMBER,
        passSubtype: true
      }, {
        argumentType: Fa.NUMBER,
        passSubtype: true
      }]
    },
    NE: {
      method: 'ne',
      parameters: [{
        argumentType: Fa.NOERROR,
        passSubtype: true
      }, {
        argumentType: Fa.NOERROR,
        passSubtype: true
      }]
    },
    POW: {
      method: 'pow',
      parameters: [{
        argumentType: Fa.NUMBER,
        passSubtype: true
      }, {
        argumentType: Fa.NUMBER,
        passSubtype: true
      }]
    },
    UMINUS: {
      method: 'uminus',
      parameters: [{
        argumentType: Fa.NUMBER,
        passSubtype: true
      }]
    },
    UNARY_PERCENT: {
      method: 'upercent',
      parameters: [{
        argumentType: Fa.NUMBER,
        passSubtype: true
      }]
    },
    UPLUS: {
      method: 'uplus',
      parameters: [{
        argumentType: Fa.NUMBER,
        passSubtype: true
      }]
    }
  };
  Hf.registerFunctionPlugin(ExkSimpleArithmerticPlugin, eksSimpleArithmerticPluginTranslations);
} // Lookup all error cells in
// Hyperform graphs nodes

export function filterErrors(hfInstance, workbook) {
  const errors = [];
  const sheetsValues = hfInstance.getAllSheetsValues();
  const sheetKeys = Object.keys(sheetsValues); // Itterate through each sheet of values
  // and search for any errors and convert
  // them into error overview instances

  for (const sheetName of sheetKeys) {
    errors.push([]); // Add sheet to errors

    const sheet = sheetsValues[sheetName];
    const sheetIndex = sheetKeys.indexOf(sheetName);

    for (const row of sheet) {
      for (const cellValue of row) {
        const constructor = cellValue && cellValue.constructor && cellValue.constructor.name || '';

        if (cellValue && constructor.search('CellError') > -1) {
          const rowIndex = sheet.indexOf(row);
          const columnIndex = row.indexOf(cellValue);
          const detailedCellError = cellValue;
          const err = {
            type: detailedCellError.type,
            error: detailedCellError.message,
            address: coordinatesToAddress(rowIndex, columnIndex, sheetIndex)
          };

          if (workbook) {
            err.sourceValue = ((workbook[sheetIndex] || [])[rowIndex] || [])[columnIndex] || '';
          }

          errors[sheetIndex].push(err);
        }
      }
    }
  }

  return errors;
} // Convert a Hyperformula error into
// an app SpreadsheetError

export function toSpreadsheetError(hfError) {
  const error = Error(hfError.error);
  error.code = hfError.type;
  error.cellRef = `${hfError.address.sheet > 0 ? hfError.address.sheet + '.' : ''}${coordinatesToAddress(hfError.address.row, hfError.address.col, hfError.address.sheet).ref}`;
  error.cellValue = hfError.sourceValue;
  return error;
} // Translations for all custom functions

const eksSimpleArithmerticPluginFunctions = {
  ADD: 'ADD',
  CONCAT: 'CONCAT',
  DIVIDE: 'DIVIDE',
  EQ: 'EQ',
  GT: 'GT',
  GTE: 'GTE',
  LT: 'LT',
  LTE: 'LTE',
  MINUS: 'MINUS',
  MULTIPLY: 'MULTIPLY',
  NE: 'NE',
  POW: 'POW',
  UMINUS: 'UMINUS',
  UNARY_PERCENT: 'UNARY_PERCENT',
  UPLUS: 'UPLUS'
}; // Apply translations to all supported languages

const eksSimpleArithmerticPluginTranslations = {
  csCZ: eksSimpleArithmerticPluginFunctions,
  daDK: eksSimpleArithmerticPluginFunctions,
  deDE: eksSimpleArithmerticPluginFunctions,
  enGB: eksSimpleArithmerticPluginFunctions,
  enUS: eksSimpleArithmerticPluginFunctions,
  esES: eksSimpleArithmerticPluginFunctions,
  fiFI: eksSimpleArithmerticPluginFunctions,
  frFR: eksSimpleArithmerticPluginFunctions,
  huHU: eksSimpleArithmerticPluginFunctions,
  itIT: eksSimpleArithmerticPluginFunctions,
  nbNO: eksSimpleArithmerticPluginFunctions,
  nlNL: eksSimpleArithmerticPluginFunctions,
  plPL: eksSimpleArithmerticPluginFunctions,
  ptPT: eksSimpleArithmerticPluginFunctions,
  ruRU: eksSimpleArithmerticPluginFunctions,
  svSE: eksSimpleArithmerticPluginFunctions,
  trTR: eksSimpleArithmerticPluginFunctions
}; // Convert a workbook into a HyperFormula Sheets instance
// SYNC with /functions/src/services/hyperFormula.ts

export function workbookToSheets(workbook, sheetNames) {
  return workbook.reduce((acc, sheet, idx) => {
    acc[getSheetName(idx, sheetNames)] = sheet;
    return acc;
  }, {});
} // Convert a Workbook instance into HyperFormula
// SYNC with /functions/src/services/hyperFormula.ts

export function workbookToHyperFormula(workbook, sheetNames) {
  if (!hyperFormula) {
    throw Error(`${PREFIX} workbookToHyperFormula: HyperFormula must be loaded before use`);
  }

  return hyperFormula.buildFromSheets(workbookToSheets(workbook, sheetNames), hyperFormulaConfig);
} // Convert a Workbook into a list of Spreadsheet
// errors (if any exist) from HyperFormula result

export function workbookToSpreadsheetErrors(workbook, sheetNames) {
  const result = workbookToHyperFormula(workbook, sheetNames);
  return flatten(filterErrors(result, workbook)).map(toSpreadsheetError);
} // Convert a HyperFormula instance into Workbook

export function hyperFormulaToWorkbook(hfInstance) {
  const workbook = [];
  const count = hfInstance.countSheets();

  for (let i = 0; i < count; i++) {
    workbook.push(hfInstance.getSheetValues(i));
  }

  return workbook;
}