import ssf from 'ssf';
import { durationNumberToString } from '@/utils/time';
import { utils as utilsCore } from '@industriousapps/excelkits-core';
import logger from '@/utils/logger';
import toMoney from './toMoney';
import toPercent from './toPercent';
const PREFIX = 'common: utils: templateEngine:';
const {
  isValidNumber,
  toFloatingPoint
} = utilsCore; // Interpolate a template string with dynamic variables
// Based on: https://krasimirtsonev.com/blog/article/Javascript-template-engine-in-just-20-line

export default function templateEngine(template, context) {
  const abbr_money = / \| abbr_money[\s||(a-z)]*/g;
  const money = / \| money[\s||\d]*/g;
  const percent = / \| percent[\s||\d]*/g;
  const duration = / \| duration[\s||\d|.|:]*/g;
  const spreadsheetFmt = / \| ssf.*/g;
  let result = `${template}`;
  let match;

  while (match = /{{([^}}]+)?}}/g.exec(result)) {
    const varName = match[1].replace(money, '').replace(percent, '').replace(abbr_money, '').replace(duration, '').replace(spreadsheetFmt, '');
    let value = typeof context[varName] !== 'undefined' ? context[varName] : '';
    const src = match[0]; // Format as rounded/abbreviated money (ie 1k)

    if (src.search(abbr_money) > 0) {
      const rounding = getLastStringArgument(src, 'up');
      const num = Math.round(toFloatingPoint(`${value}`)); // NOTE: can't be NaN

      const divisor = num >= 1000 ? 1000 : num >= 100 ? 100 : 1;
      const abbr = Math[rounding === 'up' ? 'ceil' : 'floor'](num / divisor);
      value = `${abbr * (divisor === 100 ? 100 : 1)}${divisor > 100 ? 'k' : ''}`;
    } // Format as money
    // DEPRECATED Use ssf


    if (src.search(money) > 0) {
      const round = getLastIntegerArgument(src, 2);
      value = Number(value);

      if (value !== value) {
        value = ''; // Disregard NaN values
      } else {
        value = toMoney(value, round).replace(/^\$/, '').replace(/^-\$/, '-');
      }
    } // Format as percent
    // DEPRECATED Use ssf


    if (src.search(percent) > 0) {
      const round = getLastIntegerArgument(src, 1);
      value = toPercent(Number(value), round).replace(/^\$/, '');
    } // Format to default duration (ie "hh:mm:ss")
    // DEPRECATED Use ssf


    if (src.search(duration) > 0) {
      const numericVal = Number(value);
      value = durationNumberToString(isNaN(numericVal) ? 0 : numericVal);
    } // Apply spreadsheet formatting template


    if (src.search(spreadsheetFmt) > 0) {
      let formatArg = getStringArgumentByIndex(src, 0) || 'General';

      try {
        formatArg = decodeSFFArgument(formatArg); // Decode any URL space chars
      } catch (err) {
        logger.error(Error(`${PREFIX} failed to URL decode "${formatArg}": ${err}`));
      }

      const isNum = isValidNumber(value, false);
      value = ssf.format(formatArg, isNum ? Number(value) : value).trim();
    }

    if (typeof value === 'function') {
      value = '';
    } // Update tempate


    result = result.replace(src, `${value}`);
  }

  return result.replace(/<style[^>]*>.*<\/style>/gm, '') // Remove style tags
  .replace(/<script[^>]*>.*<\/script>/gm, ''); // Remove script tags
} // Wrap template variables
// in an underline HTML tag

export function underlineTemplateVariables(tmpl) {
  return tmpl.replace(/\n/gm, '').replace(/{{/gm, '<u>{{') // Add highlights under variables
  .replace(/\$<u>{{/gm, '<u>${{') // Move money symbol into highlight
  .replace(/}}/gm, '}}</u>') // Close highlight tags
  .replace(/}}<\/u>%/gm, '}}%</u>'); // Move percent symbol into highlight
} // Return the first variable
// name in a template

export function firstTemplateVariableName(tmpl) {
  return allTemplateVariableNames(tmpl).shift() || '';
} // Return the first variable
// name in a template

export function allTemplateVariableNames(tmpl) {
  const result = [];
  const regExp = /{{([^}}]+)?}}/g;
  let match;

  while (match = regExp.exec(tmpl)) {
    match.input = ''; // delete string copy
    // store the matching variable name /wo any helpers

    result.push((match[1] || '').split(' | ')[0]);
  }

  return result;
} // Lookup last user provided number integer
// or return a default value

function getLastIntegerArgument(src) {
  let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return parseInt((src.replace('}}', '').match(/\d+$/g) || [`${defaultValue}`])[0]) || 0;
} // Lookup last user provided string
// or return a default value
// DEPRECATED: supports bad argument syntax: {{value | function arg1 | arg2}}


function getLastStringArgument(src) {
  let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  const lastArg = src.replace('}}', '').split(' | ').slice(2).pop() || '';
  return (lastArg.match(/[a-z]+$/g) || [`${defaultValue}`])[0];
} // Lookup last user provided string
// or return a default value
// NOTE: supports new argument syntax: {{value | function arg1 arg2}}


function getStringArgumentByIndex(src, index) {
  let defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  const func = src.replace('}}', '').split(' | ').pop() || '';
  const args = func.split(' ').slice(1);
  const targetArg = args[index] || '';
  return targetArg ? targetArg : `${defaultValue}`;
} // Determine if a string contains
// uninterpolated values


export function isUninterpolated(str) {
  return /{{([^}}]+)?}}/g.test(str);
} // URI encode conflicting characters

const URI_SPACE = '%20';
export function encodeSSFArgument(arg) {
  return arg.replace(/\s/g, URI_SPACE);
} // URI decode conflicting characters

function decodeSFFArgument(arg) {
  return arg.replace(/%20/g, ' ');
}